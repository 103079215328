import './scss/main.scss'

import $ from 'jquery';

window.addEventListener('wheel', function() {
    var position = window.scrollY || window.pageYOffset;

    if(position > 300){
        $("nav.header").addClass("fixed")
    }else if(position < 300){
        $("nav.header").removeClass("fixed")
    }
});